import React from 'react';
import { Box, Themed, ThemeProvider } from 'theme-ui';
import { CallToAction, Container, RichText } from '@sprinklr/shared-lib';
import { CTA } from '@sprinklr/shared-lib/@types/entities';
import { Document } from '@contentful/rich-text-types';
import {
  ContainerBottomPadding,
  ContainerTopPadding,
} from '@sprinklr/shared-lib/@types/types';
import { useContainerPadding } from '@sprinklr/shared-lib/hooks';
import { generateBannerCSS } from './generateBannerCSS';

export interface InlineBannerProps {
  bottomMargin: ContainerBottomPadding;
  topMargin: ContainerTopPadding;
  colorTheme: 'DARK' | 'LIGHT';
  desktopTextPosition: 'LEFT' | 'RIGHT';
  headline: string;
  inlineBannerSubHeadline?: { raw: Document };
  cta?: CTA;
  mediaImage: { asset: { file: { url: string } } };
  isFullWidthComponent: boolean;
}

const InlineBanner = (props: InlineBannerProps): JSX.Element => {
  const {
    bottomMargin,
    topMargin,
    colorTheme,
    desktopTextPosition,
    headline,
    inlineBannerSubHeadline,
    cta,
    mediaImage,
    isFullWidthComponent,
  } = props;
  const [pt, pb] = useContainerPadding({
    top: topMargin,
    bottom: bottomMargin,
  });
  const { mobileBackground, desktopBackground, textColor } = generateBannerCSS(
    desktopTextPosition,
    colorTheme,
  );

  return (
    <Container
      fullHeight
      fullWidth={isFullWidthComponent}
      containerSx={{ pt: pt, pb: pb }}
    >
      <Box
        sx={{
          borderRadius: '10px',
          display: 'grid',
          gridTemplateColumns: [
            '100% 100%',
            null,
            null,
            `${desktopTextPosition === 'LEFT' ? '100% 60%' : '60% 100%'}`,
          ],
          minHeight: [null, null, null, '400px'],
          overflow: 'hidden',
        }}
      >
        <Box
          sx={{
            position: 'relative',
            background: `url(${mediaImage?.asset?.file?.url})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            zIndex: -1,
            right: [
              `${desktopTextPosition === 'LEFT' ? '100%' : '0%'}`,
              null,
              null,
              `${desktopTextPosition === 'LEFT' ? '100%' : '0%'}`,
            ],
            order: desktopTextPosition === 'LEFT' ? 2 : 1,
          }}
        ></Box>
        <Box
          sx={{
            position: 'relative',
            background: [mobileBackground, null, null, desktopBackground],
            right: [
              `${desktopTextPosition === 'LEFT' ? '0%' : '100%'}`,
              null,
              null,
              `${desktopTextPosition === 'LEFT' ? '0%' : '60%'}`,
            ],
            display: 'flex',
            justifyContent: [
              'center',
              null,
              null,
              desktopTextPosition === 'LEFT' ? 'flex-start' : 'flex-end',
            ],
            order: desktopTextPosition === 'LEFT' ? 1 : 2,
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: ['center', null, null, 'flex-start'],
              textAlign: ['center', null, null, 'left'],
              pt: ['30px', '50px', '50px', '90px'],
              pb: ['40px', '60px', '60px', '100px'],
              px: ['18px', '40px', '40px', '54px'],
              maxWidth: ['100%', null, null, '54%'],
            }}
          >
            <ThemeProvider
              theme={{
                styles: {
                  p: {
                    color: textColor,
                    marginBottom: '0px',
                    fontSize: ['16px', '18px', '18px', '18px'],
                  },
                  a: {
                    color: textColor,
                    fontSize: ['16px', '18px', '18px', '18px'],
                  },
                  //H2 values may need tweaking based on lineHeight settings.
                  h2: {
                    color: textColor,
                    marginBottom: ['14px', '20px', null, '24px'],
                  },
                },
              }}
            >
              <Themed.h2>{headline}</Themed.h2>
              {inlineBannerSubHeadline && (
                <Box sx={{ marginBottom: cta ? ['20px', '30px'] : '0px' }}>
                  <RichText richTextObject={inlineBannerSubHeadline} />
                </Box>
              )}
            </ThemeProvider>
            {cta && <CallToAction {...cta} type="BUTTON" />}
          </Box>
        </Box>
      </Box>
    </Container>
  );
};

InlineBanner.displayName = 'InlineBanner';
export default InlineBanner;
