import { InlineBannerProps } from './InlineBanner';

export const generateBannerCSS = (
  desktopTextPosition: InlineBannerProps['desktopTextPosition'],
  colorTheme: InlineBannerProps['colorTheme'],
): {
  mobileBackground: string;
  desktopBackground: string;
  textColor: '#000000' | '#FFFFFF';
} => {
  const gradientMap = {
    lightMobile: 'rgba(244,247,252,0.8)',
    darkMobile: 'rgba(46,56,65,0.8)',
    lightGradient: `linear-gradient(${
      desktopTextPosition === 'LEFT' ? '90deg' : '270deg'
    }, rgba(244,247,252,1) 40%, rgba(244,247,252,0.8) 50%, rgba(244,247,252,0) 70%)`,
    darkGradient: `linear-gradient(${
      desktopTextPosition === 'LEFT' ? '90deg' : '270deg'
    }, rgba(46,56,65,1) 40%, rgba(46,56,65,0.8) 50%, rgba(0,212,255,0) 70%)`,
  };

  const mobileBackground = colorTheme === 'DARK' ? gradientMap.darkMobile : gradientMap.lightMobile;
  const desktopBackground = colorTheme === 'DARK' ? gradientMap.darkGradient : gradientMap.lightGradient;
  const textColor = colorTheme === 'LIGHT' ? '#000000' : '#FFFFFF';

  return { mobileBackground, desktopBackground, textColor };
};
